import React from 'react';
import { Box, styled } from '@mui/system';
import theme, { BREAKPOINTS, COLORS, MOBILE_SPACING } from '../../utils/theme';
import RequestResponse from '../../components/api-ref/RequestResponse';
import EndPointInfo from '../../components/api-ref/EndPointInfo';
import ParameterInfo from '../../components/api-ref/ParameterInfo';
import parse from 'html-react-parser';
import Typography from '../../components/common/Typography';
import SEO from '../../components/common/Seo';
import ReactMarkdown from 'react-markdown';
import MDXComponents from '../../components/docs/MDXComponents';
import rehypeRaw from 'rehype-raw';
import AuthInfo from '../../components/api-ref/AuthInfo';
import LoadingState from '../../components/common/LoadingState';
import { appendRouteToUrl } from '../../utils/helper';

const useStyles = {
  content: {
    display: 'flex',
    marginTop: '24px',
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      rowGap: theme.spacing(4),
    },
  },
  header: {
    padding: theme.spacing(0, 10),
  },
  descriptionStyle: {
    marginTop: '8px',
    paddingLeft: '18px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    paddingBottom: '8px',
  },
  summaryStyle: {
    paddingLeft: '18px',
  },
  endpointTagStyle: {
    marginTop: '18px',
    paddingLeft: '18px',
  },
  rightPanelContents: {
    display: 'flex',
    justifyContent: 'start',
  },
  headerStyle: {
    marginTop: '18px',
    paddingLeft: theme.spacing(10),
    paddingBottom: theme.spacing(2),
  },
  displayNoneStyle: {
    display: 'none',
  },
};
const Container = styled('div')(({ theme }) => ({
  paddingBottom: '8px',
  margin: '0px auto',
  maxWidth: '1028px',
  width: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
  [BREAKPOINTS.MOBILE]: {
    width: 'unset',
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: '0px 20px',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  maxWidth: '1028px',
  margin: '44px auto',
  marginBottom: '0px',
  width: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
  [BREAKPOINTS.MOBILE]: {
    width: 'unset',
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto 20px',
    marginTop: theme.spacing(3),
  },
}));

const LeftPanel = styled('div')(({ theme }) => ({
  width: '50%',
  [BREAKPOINTS.MOBILE]: {
    minWidth: 'unset',
    width: '100%',
  },
}));

const RightPanel = styled('div')(({ theme }) => ({
  width: '50%',
  marginLeft: theme.spacing(10),
  [BREAKPOINTS.MOBILE]: {
    minWidth: 'unset',
    width: '100%',
    marginLeft: '0px',
  },
}));

const snippetWrapper = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
} as const;

const ApiTemplate = (props) => {
  const pageContext = props.pageContext;
  const { apiData, definitions, components, info, securityDefinition } =
    pageContext;
  const securityDef = securityDefinition
    ? securityDefinition
    : components.securitySchemes;
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 0);
  }, []);
  React.useEffect(() => {
    if (typeof window !== 'undefined' && isLoading === false) {
      const hash = window.location.hash;
      appendRouteToUrl(hash);
    }
  }, [isLoading]);

  function buildAPIRef() {
    return Object.keys(apiData).map((key) => {
      const paths = apiData[key];
      return (
        <Container key={key}>
          <Box>
            {key && 
              <Typography
                variant="h1"
                id={`${key}`}
                sx={useStyles.endpointTagStyle}
                key={key}
              >
                {key}
              </Typography>
            }
            {paths.map((pathData) => {
              return (
                <Box
                  key={pathData.path + '_' + pathData.method}
                  sx={useStyles.content}
                >
                  <LeftPanel>
                    <Box>
                      <Typography
                        variant="h2"
                        id={`${pathData.operationId}`}
                        sx={useStyles.summaryStyle}
                      >
                        {pathData.summary}
                      </Typography>
                      <EndPointInfo
                        method={pathData.method}
                        path={pathData.path}
                      />
                      {pathData.description && 
                        <Typography
                          type="DESKTOP_BODY_PARAGRAPH"
                          sx={useStyles.descriptionStyle}
                        >
                          {parse(pathData.description)}
                        </Typography>
                      }
                      <ParameterInfo
                        items={pathData.parameters}
                        definitions={definitions}
                        components={components}
                        body={pathData.requestBody}
                      />
                    </Box>
                  </LeftPanel>
                  <RightPanel>
                    <Box sx={useStyles.rightPanelContents}>
                      <Box sx={snippetWrapper}>
                        <RequestResponse
                          items={pathData.snippets}
                          type="request"
                          isJSONFoldable={true}
                          snippetId={`${pathData.operationId}` + '(request)'}
                        />
                        <RequestResponse
                          items={pathData.responseList}
                          type="response"
                          isJSONFoldable={true}
                          snippetId={`${pathData.operationId}` + '(response)'}
                        />
                      </Box>
                    </Box>
                  </RightPanel>
                </Box>
              );
            })}
          </Box>
        </Container>
      );
    });
  }
  const shortCodes = {
    ...MDXComponents,
    securitydefinitions: (props) => <AuthInfo details={securityDef}></AuthInfo>,
  };
  return (
    <>
      <SEO
        title={info ? info.title : ''}
        description={info ? info.description : ''}
      />
      {isLoading && <LoadingState />}
      {
        <InfoStyle>
          <Box sx={!isLoading ? {} : useStyles.displayNoneStyle}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
              {info.description}
            </ReactMarkdown>
          </Box>
        </InfoStyle>
      }
      {
        <Box sx={!isLoading ? {} : useStyles.displayNoneStyle}>
          {buildAPIRef()}
        </Box>
      }
    </>
  );
};
export default ApiTemplate;
