import React from 'react';
import theme from '../../../utils/theme';
import { Box } from '@mui/system';
import ParameterTitle from './ParamTitle';
import Parameter from './Parameter';

const useStyles = {
  paramGroup: {
    marginTop: theme.spacing(4),
  },
};

const ParameterInfo: React.FC<{
  items?: any;
  definitions?: any;
  body?: any;
  components?: any;
}> = (props) => {
  const { definitions, components, items, body } = props;

  let groupedParametersArr: any[] = [];
  if (items) {
    groupedParametersArr = Object.values(
      props.items.reduce((group, parameter) => {
        const key = parameter['in'];
        group[key] ??= [];
        group[key].push(parameter);
        return group;
      }, {}),
    );
  }
  if (body && body.content) {
    const content = body.content;
    if (content['application/json']) {
      const bodyParam = {
        in: 'body',
        name: 'body',
        schema: content['application/json'].schema,
      };
      groupedParametersArr.push([bodyParam]);
    }
  }
  return (
    <>
      {groupedParametersArr.map((value: any, i) => {
        return (
          <Box key={i} sx={useStyles.paramGroup}>
            {value && <ParameterTitle items={value} />}
            {value &&
              value.map((param, i) => 
                <Parameter
                  param={param}
                  key={i}
                  definitions={definitions}
                  components={components}
                />
              )}
          </Box>
        );
      })}
    </>
  );
};

export default ParameterInfo;
