import React, { useState } from 'react';
import theme, { COLORS } from '../../../utils/theme';
import { Box } from '@mui/system';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import Typography from '../../common/Typography';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MDXComponents from '../../docs/MDXComponents';

const useStyles = {
  paramDescription: {
    paddingLeft: '18px',
    paddingBottom: '7px',
  },
  paramDescriptionCollapsed: {
    paddingLeft: '18px',
    paddingBottom: '0px',
  },
  paramHeadearStyle: {
    display: 'flex',
  },
  iconStyle: {
    height: '14px',
    width: '14px',
    fontSize: '14px',
    marginTop: '12px',
    padding: '0px 2px',
    color: COLORS.TEXT_LOW_EMPHASIS,
    cursor: 'pointer',
  },
  grayBackground: {
    backgroundColor: theme.palette.grey[100],
  },
  defaultBackground: {
    backgroundColor: theme.palette.common.white,
  },
  inheritBackground: {
    backgroundColor: 'inherit',
  },
  innerObjectStyle: {
    marginLeft: '18px',
    marginRight: '12px',
  },
  reactMarkdownStyles:{
    marginBlock: 'unset',
    marginInline: 'unset',
    display: 'unset',
  },
};
export interface ObjectParamProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  header: any;
  name: any;
  description?: string;
  colorFlag?: boolean;
}
const ObjectParam: React.FC<ObjectParamProps> = (props) => {
  const { header, name, description, colorFlag } = props;
  const [expandable, setExpandable] = useState(true);
  if (!name) {
    return <Box key={name}>{props.children}</Box>;
  }
  const populateColor = () => {
    if (expandable) {
      return useStyles.inheritBackground;
    }
    if (colorFlag) {
      return useStyles.grayBackground;
    } else {
      return useStyles.defaultBackground;
    }
  };
  const shortCodes = {
  a:MDXComponents.a,
  p: (props) => {
    return (
      <Typography
      type="DESKTOP_BODY_PARAGRAPH"
       sx={useStyles.reactMarkdownStyles}
        {...props}
      />
    );
  }
  };
  return (
    <Box key={name} sx={populateColor()}>
      <Box sx={useStyles.paramHeadearStyle}>
        {expandable && 
          <AddOutlinedIcon
            sx={useStyles.iconStyle}
            onClick={() => setExpandable(false)}
          />
        }
        {!expandable && 
          <RemoveOutlinedIcon
            sx={useStyles.iconStyle}
            onClick={() => setExpandable(true)}
          />
        }
        {header}
      </Box>
      {description && 
        <Typography
          whiteSpace="pre-line"
          type="DESKTOP_BODY_PARAGRAPH"
          sx={
            expandable
              ? useStyles.paramDescription
              : useStyles.paramDescriptionCollapsed
          }
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
            {description}
          </ReactMarkdown>
        </Typography>
      }
      <Box
        display={expandable ? 'none' : 'block'}
        sx={useStyles.innerObjectStyle}
      >
       {props.children}
      </Box>
    </Box>
  );
};

export default ObjectParam;
